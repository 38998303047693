const { default: styled } = require("styled-components")

export const ContentWrapper = styled.div`
  width: 100vw;
  margin: 0 auto;
  @media (max-width: 600px) {
    /* S */
    max-width: 80%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    /* M */
    max-width: 56rem;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    /* L */
    max-width: 93.6rem;
  }

  @media (min-width: 1440px) {
    /* XL */
    max-width: 125.6rem;
  }
`
