import React from "react"
import { Link, navigate } from "gatsby"
import { StyledMenu, LanguageWrapper } from "../../styles/NavbarStyle"
import { MenuLinks } from "./Navbar"
import { useLanguage } from "../../context"
import { languages } from "../../utils/languageOptions"

const Menu = ({ open, toggleMenu }) => {
  const { language, updateLanguage } = useLanguage()

  function menuNavigate(e, whereTo) {
    e.preventDefault()
    navigate(whereTo)
    toggleMenu()
  }
  return (
    <StyledMenu open={open} language={language}>
      <div>
        {MenuLinks.map(el => (
          <Link
            activeClassName="activeSideNav"
            key={el.en}
            to={`/${el.link}`}
            onClick={e => menuNavigate(e, `/${el.link}`)}
          >
            <h1>{el[language]}</h1>
          </Link>
        ))}
        <div className="captions language">
          {languages.map(el => (
            <LanguageWrapper
              onClick={() => updateLanguage(el)}
              isActive={el === language}
              key={el}
            >
              {el}
            </LanguageWrapper>
          ))}
        </div>
      </div>
      {/* /De or en */}
    </StyledMenu>
  )
}

export default Menu
