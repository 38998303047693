import React, { useState } from "react"
import styled from "styled-components"
import { ContentWrapper } from "../../styles/Wrapper"
import { phoneIco, emailIco, printIco } from "../../utils/imageUpload"
import { Link } from "gatsby"
import { useLanguage } from "../../context/LanguageContext"
import { useStaticQuery, graphql } from "gatsby"

const StyledFooter = styled.footer`
  background-color: var(--backgrounds);
  padding: 3.2rem 0;
  h5 {
    margin-bottom: 0.8rem;
  }
  section {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    .middle {
      display: grid;
      grid-template-columns: 1fr 2fr auto auto;
      grid-template-areas: "address contact main resources";
      grid-gap: 4rem;
    }
  }
  .address > div + div {
    margin-top: 0.8rem;
  }

  .contact {
    div {
      margin-bottom: 0.4rem;
    }
    img {
      margin-right: 1.2rem;
    }

    .email {
      text-decoration: underline;
    }
  }

  .contact,
  .main,
  .resources {
    div {
      margin-bottom: 0.4rem;
    }
  }
  a {
    text-decoration: none;
    color: var(--grey);
  }

  section {
    /* border-top: 1px solid lightgrey; */
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    /* M */

    .middle {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 4rem;
    }

    .phone {
      grid-column: 1 / span 3;
    }
  }

  @media (max-width: 600px) {
    .middle {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 24px;
    }
  }

  @media (max-width: 1024px) {
    .main,
    .resources {
      display: none;
    }
  }
`

const PhoneMain = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }

  .selection {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid var(--beige);
    display: flex;
    justify-content: space-between;
  }
`

const PhoneResources = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
  .selection {
    width: 100%;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--beige);
  }
`
const Footer = () => {
  const [openMain, setopenMain] = useState(false)
  const [openResources, setopenResources] = useState(false)
  const { language } = useLanguage()


  function chooseText(path, mainObj = main, language = "de") {
    return mainObj[`${path}_${language}`]
  }

  const open = value => {
    if (value === "main") {
      setopenMain(!openMain)
    } else {
      setopenResources(!openResources)
    }
  }



  const data = useStaticQuery(graphql`
  query {
    allSanityFooter {
      edges {
        node {
          serviceLink_de
          serviceLink_en
          aboutLink_en
          aboutLink_de
          mainPage_de
          mainPage_en
          resource_de
          resource_en
          contact_en
          contact_de
          documentLink_en
          documentLink_de
          careerLink_en
          careerLink_de
        }
      }
    }
  }
`)

const [main] = data.allSanityFooter.edges.map(({ node }) => node)


  return (
    <StyledFooter>
      <ContentWrapper>
        <section>
          <div className="middle">
            <div className="address">
          <h5>WTW Weidinger · Thiele · Wenninger  <br/>
             Steuerberater · Wirtschaftsprüfer <br/>
            Partnerschaftsgesellschaft mbB</h5>

              <div>
                Sendlinger Str. 7<br />
                80331 München, Deutschland
              </div>
            </div>
            <div className="contact">
              <div className="bold">{chooseText("contact", main, language)}</div>
              <div>
                <img src={phoneIco} alt="reach out via phone" /> + 49-89-21 11
                47-0
              </div>
              <div>
                <img src={printIco} alt="react hut via printer" /> + 49-89-21
                11 47-44
              </div>
              <div className="email">
                <img src={emailIco} alt="reach out via email" />
                <a href="mailto:info@wtw-muc.de">info@wtw-muc.de</a>
              </div>
            </div>
            <div className="main">
              <div className="bold">{chooseText("mainPage", main, language)}</div>
              <div>
                <Link to="/about">{chooseText("aboutLink", main, language)}</Link>
              </div>
              <div>
                <Link to="/services">{chooseText("serviceLink", main, language)}</Link>
              </div>
              <div>
                <Link to="/contact">{chooseText("contact", main, language)}</Link>
              </div>
            </div>
            <div className="resources">
              <div className="bold">{chooseText("resource", main, language)}</div>
              <div>
                <Link to="/documents">{chooseText("documentLink", main, language)}</Link>
              </div>
              <div>
                <Link to="/career">{chooseText("careerLink", main, language)}</Link>
              </div>
            </div>
            <div className="phone">
              <PhoneMain>
                <div onClick={() => open("main")} className="bold selection">
                {chooseText("mainPage", main, language)}
                  <p>{openMain ? "↓" : "↑"}</p>
                </div>
                {openMain && (
                  <>
                    <div>
                      <Link to="/services">{chooseText("serviceLink", main, language)} →</Link>
                    </div>
                    <div>
                      <Link to="/about">{chooseText("aboutLink", main, language)} →</Link>
                    </div>
                    <div>
                      <Link to="/about">{chooseText("contact", main, language)} →</Link>
                    </div>
                  </>
                )}
              </PhoneMain>
              <PhoneResources>
                <div
                  onClick={() => open("resources")}
                  className="bold selection"
                >
                  {chooseText("resource", main, language)} <p>{openResources ? "↓" : "↑"}</p>
                </div>
                {openResources && (
                  <>
                    <div>
                      <Link to="/documents">{chooseText("documentLink", main, language)} →</Link>
                    </div>
                    <div>
                      <Link to="/contact">{chooseText("careerLink", main, language)} →</Link>
                    </div>
                  </>
                )}
              </PhoneResources>
            </div>
          </div>
        </section>
      </ContentWrapper>
    </StyledFooter>
  )
}

export default Footer
