import { useState, useEffect } from "react"

export function useDisplaySize() {
  const [display, setDisplay] = useState("XL")

  useEffect(() => {
    function displaySize() {
      const screen = window.innerWidth
      if (screen >= 1440) {
        return "XL"
      }
      if (screen < 1440 && screen >= 936) {
        return "L"
      }
      if (screen < 936 && screen > 600) {
        return "M"
      }
      return "S"
    }
    setDisplay(displaySize())
  }, [])

  useEffect(() => {
    function changeDisplay() {
      const screen = window.innerWidth
      if (screen >= 1440) {
        return setDisplay("XL")
      }
      if (screen < 1440 && screen >= 936) {
        return setDisplay("L")
      }
      if (screen < 936 && screen > 600) {
        return setDisplay("M")
      }
      return setDisplay("S")
    }
    window && window.addEventListener("resize", changeDisplay)

    return () => window && window.removeEventListener("resize", changeDisplay)
  }, [display])

  return { display }
}
