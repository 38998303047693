import React, { useState } from "react"
import { StyledBurger } from "../../styles/NavbarStyle"
import { useNav } from "../../context"

const Burger = ({ isMenuOpen, toggleMenu }) => {
  const { inView, isSpecial } = useNav()
  return (
    <StyledBurger
      open={isMenuOpen}
      onClick={toggleMenu}
      inView={inView}
      isSpecial={isSpecial}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
export default Burger
