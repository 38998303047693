import React from "react"
import {
  wtwWhiteLogo,
  wtwGreenLogo,
  wtwGreenOnly,
  wtwWhiteOnly
} from "../../utils/imageUpload"
import styled from "styled-components"
import { useLanguage } from "../../context/LanguageContext"
import { Link } from "gatsby"
import { useDisplaySize } from "../../hooks/displaySize.hook"
import { useMenuOpen } from "../../hooks/useMenuOpen.hook"
import { ContentWrapper } from "../../styles/Wrapper"
import Burger from "./Burger"
import Menu from "./Menu"
import { BurgerWrapper, LanguageWrapper } from "../../styles/NavbarStyle"
import { useNav } from "../../context"
import { languages } from "../../utils/languageOptions"
import { useStaticQuery, graphql } from "gatsby"

export const MenuLinks = [
  {
    de: "Über uns",
    link: "about",
    en: "About Us"
  },
  {
    de: "Leistungen",
    link: "services",
    en: "Services"
  },
  {
    de: "Dokumente",
    link: "documents",
    en: "Documents"
  },
  {
    de: "Karriere",
    link: "career",
    en: "Career"
  },
  {
    de: "Kontakt",
    link: "contact",
    en: "Contact"
  }
]

const logos = {
  white: {
    BIG: wtwWhiteLogo,
    S: wtwWhiteOnly
  },
  green: {
    BIG: wtwGreenLogo,
    S: wtwGreenOnly
  }
}

const Navbar = () => {
  const { inView, isSpecial } = useNav()

  const { isMenuOpen, toggleMenu } = useMenuOpen()

  const { language, updateLanguage } = useLanguage()
  const { display } = useDisplaySize()
  const navSize = display === "S" ? "S" : "BIG"
  // const [servicesList] = data.allSanityHomepage.edges.map(({ node }) => node)

  const colorLogo = inView && isSpecial ? logos.white : logos.green
  const data = useStaticQuery(graphql`
    query {
      allSanityHomepage {
        edges {
          node {
            servicesList_de
            servicesList_en
          }
        }
      }
    }
  `)

  const [servicesList] = data.allSanityHomepage.edges.map(({ node }) => node)

  const serviceArr = new Array(servicesList.servicesList_en.length)
    .fill()
    .map((_, i) => ({
      de: servicesList.servicesList_de[i],
      en: servicesList.servicesList_en[i]
    }))



    const aboutArr = [{de:"Wir beraten bei", en:"We consult in" , link:"detail"},{de:"Wir helfen bei", en:"We help with", link:"detail"},{de:"Wir schützen vor", en:"We protect", link:"detail"},{de:"Unser Team", en:"Our Team", link:"team"}]
  return (
    <Nav inView={inView} isSpecial={isSpecial}>
      <ContentWrapper style={{ height: "100%" }}>
        <StyledNav
          size={MenuLinks.length + 1}
          inView={inView}
          isSpecial={isSpecial}
        >
          <Link to="/">
            <img src={colorLogo[navSize]} />
          </Link>
          <div className="menuLinks">
            {MenuLinks.map((el, i) => {
              if (el.link === "services") {
                return (
                  <div key={i} className="dropdown">
                    <Link to="/services" className="dropbtn">
                      {el[language]} 
                    </Link>
                    <div className="dropdown-content">
                      {serviceArr.map((el, i) => {
                        return (
                          <Link key={i} to={`/services/#${el[language]}`}>
                            {el[language]}
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                )
              } 
              else if (el.link === "about") {
                return (
                  <div key={i} className="dropdown">
                    <Link to="/about" className="dropbtn">
                      {el[language]} 
                    </Link>
                    <div className="dropdown-content">
                      {aboutArr.map((el, i) => {
                        return (
                          <Link key={i} to={`/about/#${el.link}`}>
                            {el[language]}
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                )
              } else {
                return (
                  <Link key={el.en} to={`/${el.link}`} className="menu">
                    {el[language]}
                  </Link>
                )
              }
            })}
            <div className="languages captions">
              {languages.map(el => (
                <LanguageWrapper
                  onClick={() => updateLanguage(el)}
                  isActive={el === language}
                  key={el}
                >
                  {el}
                </LanguageWrapper>
              ))}
            </div>
          </div>
          {/* <Link to="/contact">
            <Button outline white={inView && isSpecial}>
              Let's work together
            </Button>
          </Link> */}

          <BurgerWrapper>
            <Burger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <Menu open={isMenuOpen} toggleMenu={toggleMenu} />
          </BurgerWrapper>
        </StyledNav>
      </ContentWrapper>
      {isMenuOpen && <Shadow toggleMenu={toggleMenu} />}
    </Nav>
  )
}

export default Navbar

const Shadow = ({ toggleMenu }) => {
  return <ShadowContainer onClick={toggleMenu}></ShadowContainer>
}

const ShadowContainer = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: -100;
  background: var(--beige);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
`

const Nav = styled.nav`
  width: 100vw;
  position: fixed;
  height: 6rem;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: ${props => {
    if (!props.inView && !props.isSpecial) {
      return "var(--backgrounds)"
    }
    if (props.inView) return "none"
    return "white"
  }};
`

const StyledNav = styled.nav`
  /* height: 3.6rem;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw; */
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;

  align-content: center;
  justify-content: space-between;
  grid-column-gap: 5rem;

  a {
    display: flex;
  }
  img {
    align-self: center;
  }

  .menuLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: ${props => {
        if (props.isSpecial && props.inView) {
          return "white"
        }
        return "var(--blue)"
        /* return props.inView ? "white" : "var(--blue)" */
      }};
    }

    .languages {
      margin-left: 8rem;
      display: flex;
      color: ${props => {
        if (props.isSpecial && props.inView) {
          return "white"
        }
        return "var(--blue)"
        /* return props.inView ? "white" : "var(--blue)" */
      }};
      p {
        cursor: pointer;
      }
      p:last-child {
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 1024px) {
    .menuLinks {
      display: none;
    }
  }

  @media (min-width: 936px) {
  }
  @media (min-width: 1440px) {
    grid-template-columns: auto auto;
    .menuLinks {
      display: grid;
      grid-template-columns: repeat(${props => props.size}, auto);
      grid-column-gap: 2rem;
    }
  }

  .dropbtn {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 400;
    color: ${props => {
      if (props.isSpecial && props.inView) {
        return "white"
      }
      return "var(--blue)"
      /* return props.inView ? "white" : "var(--blue)" */
    }};
    font-family: "Archivo", sans-serif;
    font-size: 1.4rem;
    line-height: 150%;


/* :hover{
    :after {
      content: "";
    } */
/* } */
  }

  .dropdown {
    position: relative;
    display: inline-block;
    font-family: "Archivo", sans-serif;
    font-size: 1.4rem;
    line-height: 150%;
  }

  .dropdown-content {
    display: none;
    margin: 1rem;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 250px;
    z-index: 1;
    border-radius: 6px;
    :after {
      position: absolute;
      left: 1%;
      top: -12px;
      width: 0;
      height: 0;
      content: "";
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #f9f9f9;
    }
  }
  .dropdown-content a {
    color: var(--grey);
    font-family: "Archivo", sans-serif;
    /* font-size: 1.4rem; */
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    color: var(--blue);

    :after {
      content: " →";
    }
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
  }
`
