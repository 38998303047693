import styled from "styled-components"

export const StyledBurger = styled.button`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "white" : "white")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: ${({ open, inView, isSpecial }) => {
      if (open || (inView && isSpecial)) {
        return "white"
      }

      return "var(--green)"
    }};

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

export const StyledMenu = styled.nav`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  flex-direction: column;

  justify-content: center;
  background: var(--green);
  transform: ${({ open }) => (open ? "translateX(25%)" : "translateX(100%)")};
  height: 100vh;
  width: 100%;

  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    justify-content: center;
    // width: 75%;
    padding-right: 72px;

    a {
      text-decoration: none;
      transition: color 0.3s linear;
      color: white;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 64px;
    }
  }

  .language {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    /* position: absolute; */

    /* bottom: 0; */
    /* margin-bottom: 12rem; */

    p :first-child {
      border-right: 1px solid white;
    }
  }

  .activeSideNav {
    color: white;
    font-weight: 800;
  }
`

export const LanguageWrapper = styled.p`
  padding: 0 10px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: ${props => (props.isActive ? "900" : "100")};
  opacity: ${props => (props.isActive ? "100%" : "40%")};
`

export const BurgerWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 1025px) {
    display: none;
    width: auto;
  }
`
