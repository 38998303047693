/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Navbar from "./Navigation/Navbar"
import Footer from "./Navigation/Footer"
import CopyrightSection from "./Navigation/CopyrightSection"
import { GlobalStyle } from "../styles/GlobalStyle"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />

      <Navbar />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
      <CopyrightSection />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
