const { createGlobalStyle } = require("styled-components")

const GlobalStyle = createGlobalStyle`
   @import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
   @import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;1,700&display=swap');

   * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
   }

   html {
      --grey: #333333;
      --green: #0E6A65;
      --blue: #00929A;
      --beige: #E8E2D4;
      --backgrounds: #F8F6F3;
      --captionColor: rgba(248,246,243, 0.6);
      --alert: #DC6B0B;
      --navHeight: 6rem;
      font-size: 10px;
      scroll-behavior: smooth;
   }




   body {
      overflow-x: hidden;
      color: var(--grey);
   }

   a {
      text-decoration: none;
   }

   button {
      font-family: 'Archivo', sans-serif;
      font-size: 1.6rem;
      line-height: 150%;
      font-weight: bold;



      :hover{
         cursor:pointer;
      }
      :focus{
         outline:none;
      }
   }

   ul {
      list-style-type: none;
   }



   .body {
      font-family:'Lora', serif;
      font-size: 1.6rem;
      line-height: 150%;
   }

   .links {
      font-family: 'Archivo', sans-serif;
      font-size: 1.6rem;
      line-height: 150%;
      font-weight: bold;

   }

   .sections {
      text-transform: uppercase;
      font-family: 'Archivo', sans-serif;
      font-size: 1.2rem;
      line-height: 150%;
      letter-spacing: 0.3em;
   }


   h1 {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      font-size: 2.6rem;
      line-height: 130%;

      @media (max-width:600px) {
         font-size: 2.6rem;
      }

      @media (min-width: 600px) {
         font-size: 3.6rem
      }

      @media (min-width:1024px) {
         font-size: 3.6rem
      }

   }

   h2 {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      line-height: 130%;
      font-size: 2.2rem;

      @media (max-width:600px) {
         font-size: 2.2rem;
      }

      @media (min-width:600px) {
         font-size:2.8rem;
      }

      @media (min-width:1024px) {
         font-size: 3.6rem;
      }
    
   }

   h3 {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      font-size: 2rem;
      line-height: 130%;

      @media (max-width:600px) {
         font-size: 2rem;
      }

      @media (min-width: 600px) {
         font-size: 2.4rem;
      }

      @media (min-width: 1024px) {
         font-size: 2.8rem;
      }
   }

   h4 {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 150%;

      @media (max-width:600px) {
         font-size: 1.8rem;
      }

      @media (min-width:600px) {
         font-size: 1.8rem;
      }

      @media (min-width: 1024px) {
         font-size: 1.8rem; 
      }
   }

   h5 {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 150%;

      @media (max-width:600px) {
         font-size: 1.6rem;
      }

      @media (min-width:600px) {
         font-size: 1.6rem;
      }

      @media (min-width: 1024px) {
         font-size: 1.6rem; 
      }
   }

   footer {
      font-family: 'Archivo', sans-serif;
      font-size: 1.4rem;
      line-height:150%;
      a {
         font-family: 'Archivo', sans-serif;
         font-size: 1.4rem;
         line-height:150%;
      }

     

      @media (min-width: 600px) {
         font-size: 1.4rem;
         a {
            font-size: 1.4rem;
         }
      }

      
      
      @media (min-width: 1024px) {
         font-size: 1.5rem;
         a {
            font-size: 1.5rem;
         }
      }
   }

   .bold {
      font-family: 'Lora', sans-serif;
      font-weight: bold;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
   }

   .captions {
      font-family: 'Archivo', sans-serif;
      font-size: 1.2rem;
      line-height: 150%;

      @media (min-width: 600px) {
         font-size: 1.2rem;
      }
      @media (min-width: 1024px) {
         font-size: 1.2rem
      }
   }

   .menu {
      font-family: 'Archivo', sans-serif;
      font-size: 1.4rem;
      line-height: 150%;
   }


   .info{
      font-family: Archivo;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 150%;
   }

  

   .grey {
      color: var(--grey);
    }
`

// XL > min 1440
// L > min 1024
// M >= min 600
// S < max 600

export { GlobalStyle }
