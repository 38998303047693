// NavBar
import wtwWhiteLogo from "../images/wtw-white-logo.svg"
import wtwGreenLogo from "../images/wtw-green-logo.svg"
import wtwWhiteOnly from "../images/wtw-only-white.png"
import wtwGreenOnly from "../images/wtw-only-green.png"

// Icons
import speechBubble from "../images/001-speech-bubble.svg"
import notebook from "../images/004-notebook.svg"
import graphBar from "../images/005-graph-bar.svg"
import calculator from "../images/002-calculator.svg"
import Banner from "../images/Banner.png"
import BannerPhone from "../images/BannerPhone.png"
import BannerPhoneColor from "../images/BannerPhoneColor.png"
import services2 from "../images/services/services2.png"

// Footer
import phoneIcon from "../images/footer/phoneIcon.svg"
import emailIcon from "../images/footer/emailIcon.svg"
import printerIcon from "../images/footer/printerIcon.svg"

// About Us

import aboutUsLeft from "../images/about-us/left.jpg"
import aboutUsCenter from "../images/about-us/center.jpg"
import aboutUsRight from "../images/about-us/right.jpg"
import beigeRightArrow from "../images/about-us/beigeRightArrow.png"
import greyRightArrow from "../images/about-us/greyRightArrow.png"

//Footer

import phoneIco from "../images/contact/call.png"
import emailIco from "../images/contact/email.png"
import printIco from "../images/contact/group.png"
import houseIco from "../images/contact/vector.png"

import success from "../images/contact/success.png"
import alert from "../images/contact/alert.png"
//documents

import download from "../images/documents/download.png"

export {
  alert,
  success,
  download,
  phoneIco,
  emailIco,
  printIco,
  houseIco,
  Banner,
  BannerPhone,
  BannerPhoneColor,
  services2,
  notebook,
  graphBar,
  calculator,
  speechBubble,
  wtwWhiteLogo,
  wtwWhiteOnly,
  wtwGreenLogo,
  wtwGreenOnly,
  phoneIcon,
  emailIcon,
  printerIcon,
  aboutUsLeft,
  aboutUsCenter,
  aboutUsRight,
  beigeRightArrow,
  greyRightArrow
}
