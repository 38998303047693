import React from "react"
import styled from "styled-components"
import { ContentWrapper } from "../../styles/Wrapper"
import { Link } from "gatsby"

const Copyright = styled.section`
  min-height: 4.8rem;
  background-color: var(--grey);
  align-items: center;
  display: flex;
  .copyright {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .caption {
    color: var(--captionColor);
    text-decoration: none;
  }
  .legal {
    .captions {
      color: var(--backgrounds);
      margin-bottom: 8px;
    }
    color: var(--backgrounds);
    a + a {
      margin-left: 2rem;
      color: var(--backgrounds);
    }
  }

  @media (max-width: 1024px) {
    /* M */
    padding: 16px 0;
    .copyright {
      flex-direction: column-reverse;
      justify-content: center;
    }

    .caption,
    .legal {
      width: 100%;
    }
  }
`

const CopyrightSection = () => {
  return (
    <Copyright>
      <ContentWrapper style={{ height: "100%" }}>
        <div className="copyright">
          <div className="caption captions">
            &copy; 2020 WTW Weidinger · Thiele · Wenninger | All rights reserved
          </div>
          <div className="legal">
            <Link to="/Imprint_and_Privacy" className="captions">
              Impressum
            </Link>
            <Link to="/Imprint_and_Privacy" className="captions">
              Datenschutz
            </Link>
          </div>
        </div>
      </ContentWrapper>
    </Copyright>
  )
}

export default CopyrightSection
