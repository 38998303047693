import { useState } from "react"

export function useMenuOpen() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  function toggleMenu() {
    setIsMenuOpen(m => !m)
  }

  return { isMenuOpen, toggleMenu }
}
